// Exposes an auth0 plugin
import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { App, Plugin, computed, reactive, watchEffect, toRefs } from 'vue';
import { NavigationGuardWithThis } from 'vue-router';

let client: Auth0Client;

export type ActionType = 'write' | 'read' | 'any';
export type PermissionResourcesScope = 'own' | 'group' | 'world';

export interface Permission {
  action: ActionType;
  resource: string;
  scope: PermissionResourcesScope;
}

export type existingResourceTypes =
  | 'licenses'
  | 'files'
  | 'publications'
  | 'audiobooks'
  | 'namespaces'
  | 'periodicals'
  | 'shares'
  | 'users';

// A custom claim in the ID token. Not to be confused with the permissions claim
// in an access token.
const customPermissionsClaim = 'https://warehouse.demarque.dev/permissions';
const customNamespacesClaim = 'https://warehouse.demarque.dev/namespaces';

interface Auth0PluginState {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  popupOpen: boolean;
  error: any;
}

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
});

async function handleRedirectCallback() {
  state.loading = true;
  try {
    await client.handleRedirectCallback();
    state.user = await client.getUser();
    state.isAuthenticated = true;
  } catch (e) {
    state.error = e;
  } finally {
    state.loading = false;
  }
}

export function getUserPermissions(): string[] | undefined {
  return state?.user?.[customPermissionsClaim];
}

function getAllPermissions(): string[] {
  const all = [];
  for (const action of ['read', 'write']) {
    for (const scope of ['', 'group']) {
      for (const resource of [
        'licenses',
        'files',
        'publications',
        'audiobooks',
        'namespaces',
        'periodicals',
        'shares',
        'users',
      ]) {
        const permission = `${action}:${resource}`;
        if (scope) {
          all.push(`${permission}:${scope}`);
        } else {
          all.push(permission);
        }
      }
    }
  }
  return all;
}

export function getUserNamespaces():
  | { id: string; name: string }[]
  | undefined {
  return state?.user?.[customNamespacesClaim];
}

function canReadResourceTypeOnAllNamespaces(
  resourceType: existingResourceTypes
): boolean {
  return validateActionOnAllNamespacesForResourceType('read', resourceType);
}

function canWriteResourceTypeOnAllNamespaces(
  resourceType: existingResourceTypes
): boolean {
  return validateActionOnAllNamespacesForResourceType('write', resourceType);
}

function canReadResourceTypeOnAtLeastOneNamespace(
  resourceType: existingResourceTypes
): boolean {
  return validateActionOnAtLeastOneNamespaceForResourceType(
    'read',
    resourceType
  );
}

function canWriteResourceTypeOnAtLeastOneNamespace(
  resourceType: existingResourceTypes
): boolean {
  return validateActionOnAtLeastOneNamespaceForResourceType(
    'write',
    resourceType
  );
}

function validateActionOnAllNamespacesForResourceType(
  action: string,
  resourceType: existingResourceTypes
): boolean {
  const permissions = getUserPermissions();

  if (permissions == null) {
    return false;
  }

  for (const permission of permissions.map(parsePermission)) {
    if (
      (permission.action === action || permission.action === 'any') &&
      (permission.resource === resourceType || permission.resource === 'any') &&
      permission.scope === 'world'
    ) {
      return true;
    }
  }
  return false;
}

function validateActionOnAtLeastOneNamespaceForResourceType(
  action: string,
  resourceType: existingResourceTypes
): boolean {
  const permissions = getUserPermissions();

  if (permissions == null) {
    return false;
  }

  for (const permission of permissions.map(parsePermission)) {
    if (
      (permission.action === action || permission.action === 'any') &&
      (permission.resource === resourceType || permission.resource === 'any')
    ) {
      return true;
    }
  }
  return false;
}

function parsePermission(permission: string): Permission {
  const parts = permission.split(':');
  if (parts.length < 2 || parts.length > 3) {
    throw new Error(
      `Unable to parse permission '${permission}'. It must be of the form 'action:resource[:scope]'.`
    );
  }

  if (parts[2] != null && !['own', 'group', 'world'].includes(parts[2])) {
    throw new Error(
      `Wrong scope '${parts[2]}', must be one of 'own','group', 'world'.}`
    );
  }

  return {
    action: parts[0] as ActionType,
    resource: parts[1],
    scope: (parts[2] || 'world') as PermissionResourcesScope,
  };
}

function loginWithRedirect(o?: RedirectLoginOptions) {
  return client.loginWithRedirect(o);
}

function getIdTokenClaims(o: GetIdTokenClaimsOptions) {
  return client.getIdTokenClaims(o);
}

function getTokenSilently(o: GetTokenSilentlyOptions) {
  return client.getTokenSilently(o);
}

function getTokenWithPopup(o: GetTokenWithPopupOptions) {
  return client.getTokenWithPopup(o);
}

function logout(o?: LogoutOptions) {
  return client.logout(o);
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  logout,
};

const routeGuard: NavigationGuardWithThis<undefined> = (
  to: any,
  from: any,
  next: any
) => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin;

  const verify = async () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      return next();
    }

    // Otherwise, log in
    await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify();
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (!loading.value) {
      return verify();
    }
  });
};

interface Auth0PluginOptions {
  domain: string;
  clientId: string;
  audience: string;
  redirectUri: string;

  onRedirectCallback(appState: any): void;
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  client = await createAuth0Client({
    // domain: process.env.VUE_APP_AUTH0_DOMAIN,
    // client_id: process.env.VUE_APP_AUTH0_CLIENT_KEY,
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: options.redirectUri,
    cacheLocation: 'localstorage',
  });
  try {
    // If the user is returning to the app after authentication
    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback();
      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      options.onRedirectCallback(appState);
    }
  } catch (e) {
    state.error = e;
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated();
    state.user = await client.getUser();
    state.loading = false;
  }
  return {
    install: (app: App) => {
      app.provide('Auth', authPlugin);
    },
  };
}

interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>;
  routeGuard: NavigationGuardWithThis<undefined>;
}

export const Auth0: Auth0Plugin = {
  init,
  routeGuard,
};

export async function setupAuth(options: Auth0PluginOptions) {
  await init(options);
}

export function useAuth() {
  return {
    ...toRefs(state),
    getIdTokenClaims,
    getTokenSilently,
    getTokenWithPopup,
    handleRedirectCallback,
    loginWithRedirect,
    logout,
    getUserNamespaces,
    canWriteResourceTypeOnAtLeastOneNamespace,
    canReadResourceTypeOnAtLeastOneNamespace,
    canReadResourceTypeOnAllNamespaces,
    canWriteResourceTypeOnAllNamespaces,
  };
}
